import { Controller } from "@hotwired/stimulus";
import * as bootstrap from "bootstrap";

export default class extends Controller {
  static targets = [
    "status",
    "estimateStatus",
    "estimateStatusHistory",
    "historyStatusDropdown",
    "userName",
    "bomWoInfo",
    "workOrder",
  ];

  submitForm(event) {
    var field = event.target;
    this.element.form.requestSubmit();
    this.currentValue = field.value;
  }

  updateStatus(event) {
    const selected_status = event.target.value;
    const estimate_id = event.target.dataset.value;

    if (selected_status === "draft" || selected_status === "sent") {
      event.target.closest("form").requestSubmit();
    } else {
      this.toggleStatusModal(selected_status, estimate_id);
    }
  }

  setStatus(event) {
    const selected_status = event.target.dataset.value;
    const estimate_id = event.target.dataset.estimateId;

    this.toggleStatusModal(selected_status, estimate_id);
  }

  toggleStatusModal(selected_status = "", estimate_id) {
    const status_modal = this.setStatusModal(estimate_id);
    const modalTarget = new bootstrap.Modal(status_modal);

    this.setStatusDropdowm(estimate_id).value = selected_status;
    this.updateUserField(selected_status, estimate_id);
    this.updateBomWoInfo(selected_status, estimate_id);
    modalTarget.toggle();
  }

  toggleUserField(event) {
    const estimate_id = event.target.dataset.value;
    const selected_status = this.setStatusDropdowm(estimate_id).value;

    this.updateUserField(selected_status, estimate_id);
    this.updateBomWoInfo(selected_status, estimate_id);
  }

  toggleWorkOrderField(event) {
    const estimate_id = event.target.dataset.value;

    this.setBomWoInfoBlock(estimate_id).classList.add("d-none");
    this.setWorkOrderField(estimate_id).classList.remove("d-none");
  }

  showeWorkOrderInfo(event) {
    const estimate_id = event.target.dataset.value;

    this.setBomWoInfoBlock(estimate_id).classList.remove("d-none");
    this.setWorkOrderDropdown(estimate_id).value = "";
    this.setWorkOrderField(estimate_id).classList.add("d-none");
  }

  updateUserField(selected_status, estimate_id) {
    if (selected_status === "confirmed" || selected_status === "declined") {
      this.setUserField(estimate_id).classList.remove("d-none");
    } else {
      this.setUserField(estimate_id).classList.add("d-none");
    }
  }

  updateBomWoInfo(selected_status, estimate_id) {
    if (selected_status === "approved") {
      this.setBomWoInfoBlock(estimate_id).classList.remove("d-none");
    } else {
      this.setBomWoInfoBlock(estimate_id).classList.add("d-none");
      this.setWorkOrderField(estimate_id).classList.add("d-none");
    }
  }

  setUserField(estimate_id) {
    return this.element.querySelector("#user_field_estimate_" + estimate_id);
  }

  setBomWoInfoBlock(estimate_id) {
    return this.element.querySelector("#wo_bom_info_" + estimate_id);
  }

  setWorkOrderField(estimate_id) {
    return this.element.querySelector("#existing_wo_" + estimate_id);
  }

  setWorkOrderDropdown(estimate_id) {
    return this.element.querySelector("#work_order_dropdown_" + estimate_id);
  }

  setStatusModal(estimate_id) {
    return this.element.querySelector("#estimate_" + estimate_id);
  }

  setStatusDropdowm(estimate_id) {
    return this.element.querySelector(
      "#status_dropdown_estimate_" + estimate_id,
    );
  }
}
