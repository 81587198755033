import { Controller } from "@hotwired/stimulus";
// import { enter, leave } from "el-transition";

let TIMEOUT_MILLISECONDS = 4000;

export default class extends Controller {
  static values = {
    time: Number,
  };

  connect() {
    this._disappear_time = this.hasTimeValue
      ? parseInt(this.timeValue) * 1000
      : TIMEOUT_MILLISECONDS;
    //enter(this.element).then(() => {
    setTimeout(() => {
      this.close();
    }, this._disappear_time);
    //});
  }

  close() {
    //leave(this.element).then(() => {
    this.element.remove();
    //});
  }
}
