import { Controller } from "@hotwired/stimulus";
import * as bootstrap from "bootstrap";

export default class extends Controller {
  static targets = ["autoAdjustButton", "autoadjustForm", "receiveItemsForm"];

  adjustQuantity() {
    const isChecked = this.autoAdjustButtonTarget.checked;
    if (isChecked) {
      this.autoAdjustButtonTarget.value = 1;
    } else {
      this.autoAdjustButtonTarget.value = 0;
    }
    this.autoadjustFormTarget.requestSubmit();
  }

  receiveItemForm() {
    this.receiveItemsFormTarget.submit();
  }
}
