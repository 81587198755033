import { Controller } from "@hotwired/stimulus";

function number_to_string_padded(value) {
  return Math.floor(value).toString().padStart(2, "0");
}

export default class extends Controller {
  static values = {
    from: String,
  };

  connect() {
    this._seconds = this.hasFromValue && parseInt(this.fromValue);
    this._seconds = this._seconds || 0;
    this._timer = setInterval(() => {
      this.update();
    }, 1000);
    this.update();
  }

  update() {
    this._seconds += 1;
    // this.element.innerText = new Date(this._seconds * 1000).toISOString().substring(11, 19);
    this.element.innerText = `${number_to_string_padded(
      this._seconds / 60 / 60,
    )}:${number_to_string_padded(
      (this._seconds / 60) % 60,
    )}:${number_to_string_padded((this._seconds % 60) % 60)}`;
  }

  disconnect() {
    const timer = this._timer;
    if (!timer) return;
    clearInterval(timer);
  }
}
