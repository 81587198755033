import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="single-click"
export default class extends Controller {
  do(event) {
    const target = event.currentTarget;
    if (target.hasAttribute("href")) {
      if (target.getAttribute("href") === "#/") {
        event.preventDefault();
      } else {
        setTimeout(() => {
          target.setAttribute("href", "#/");
          target.removeAttribute("data-turbo-frame");
        }, 1000);
      }
    }
  }
}
