// Controller for Bootstrap tooltips
import { Controller } from "@hotwired/stimulus";
import * as bootstrap from "bootstrap";

export default class extends Controller {
  connect() {
    var tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]'),
    );

    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      var tooltip = new bootstrap.Tooltip(tooltipTriggerEl);

      function updateTooltipState() {
        if (window.innerWidth < 768) {
          tooltip.disable();
        } else {
          tooltip.enable();
        }
      }

      window.addEventListener("resize", updateTooltipState);
      updateTooltipState();
      return tooltip;
    });
  }
}
