import { Controller } from "@hotwired/stimulus";
import * as bootstrap from "bootstrap";

export default class extends Controller {
  static targets = ["open"];

  connect() {
    this.modal = new bootstrap.Modal(this.element);
    // this.element.addEventListener('turbo:submit-end', (event) => {
    //   if (event.detail.success) {
    //     this.modal.hide();
    //   }
    // });
  }

  open() {
    if (!this.modal.isOpened) {
      this.modal.show();
    }
  }

  close(event) {
    if (event.detail.success) {
      this.modal.hide();
    }
  }

  openTargetConnected() {
    this.open();
  }

  openTargetDisconnected() {
    this.modal.hide();
  }
}
