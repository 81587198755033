import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["options", "selectedOption"];

  connect() {
    this.selectedOptionTarget.addEventListener(
      "click",
      this.toggleOptions.bind(this),
    );
    document.addEventListener("click", this.closeOptions.bind(this));
  }

  disconnect() {
    this.selectedOptionTarget.removeEventListener("click", this.toggleOptions);
    document.removeEventListener("click", this.closeOptions);
  }

  toggleOptions(event) {
    // event.stopPropagation();
    this.optionsTarget.classList.toggle("its-options--visible");
  }

  closeOptions(event) {
    if (!event.target.closest(".its-select")) {
      this.optionsTarget.classList.remove("its-options--visible");
    }
  }

  selectOption(event) {
    // event.preventDefault();
    this.selectedOptionTarget.innerHTML = event.currentTarget.innerHTML;
    this.optionsTarget.classList.remove("its-options--visible");
  }
}
