import { Controller } from "@hotwired/stimulus";
import { getNextSibling } from "../helpers";

// Connects to data-controller="tab-selector"
export default class extends Controller {
  selectTab(event) {
    const target = event.currentTarget;
    if (target.hasAttribute("href")) {
      if (target.getAttribute("href") === "#/") {
        event.preventDefault();
      } else {
        setTimeout(() => {
          target.setAttribute("href", "#/");
          target.removeAttribute("data-turbo-frame");
        }, 1000);
      }
    }
    const prevActive = this.element.querySelector(".active");
    if (prevActive) prevActive.classList.remove("active");
    target.classList.add("active");
    const tabContent = getNextSibling(this.element, ".tab-content");
    tabContent.querySelector(".active.show").classList.remove("active", "show");
    tabContent.querySelector(event.params.id).classList.add("active", "show");
  }
}
