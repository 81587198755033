import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["option"];

  toggle_sort_option() {
    this.optionTarget.classList.toggle("d-none");
  }

  toggle_quick_filters(event) {
    const clickedButton = event.target;
    const button_value = clickedButton.dataset.filterValue;
    const search_form = this.element.querySelector("#search_form");
    const filter_field = this.element.querySelector("#filter_value_field");
    const groupButtons = this.element.querySelectorAll(
      '[data-bs-toggle="button"]',
    );

    groupButtons.forEach((button) => {
      button.classList.remove("active");
    });

    filter_field.value = button_value;
    clickedButton.classList.add("active");
    search_form.submit();
  }

  remove_selected_option(event) {
    const radioButtons = this.element.querySelectorAll(".search_radio_option");
    radioButtons.forEach((radioButton) => {
      if (radioButton.checked) {
        radioButton.checked = false;
      }
    });
  }

  remove_selected_option_list(event) {
    const dropdown = this.element.querySelector(".form-select");
    if (dropdown) {
      dropdown.selectedIndex = 0;
    }
  }
}
