// Controller for Bootstrap tooltips
import { Controller } from "@hotwired/stimulus";
import * as bootstrap from "bootstrap";

export default class extends Controller {
  static targets = [
    "mainAddressFirst",
    "mainAddressSecond",
    "mainCity",
    "mainState",
    "mainZip",
    "billingAddressFirst",
    "billingAddressSecond",
    "billingCity",
    "billingState",
    "billingZip",
    "otherAddressFirst",
    "otherAddressSecond",
    "otherCity",
    "otherState",
    "otherZip",
  ];

  copyMainAddress(event) {
    event.preventDefault();
    const main_address_first = this.mainAddressFirstTarget.value;
    const main_address_second = this.mainAddressSecondTarget.value;
    const main_city = this.mainCityTarget.value;
    const main_state = this.mainStateTarget.value;
    const main_zip = this.mainZipTarget.value;
    this.billingAddressFirstTarget.value = main_address_first;
    this.billingAddressSecondTarget.value = main_address_second;
    this.billingCityTarget.value = main_city;
    this.billingStateTarget.value = main_state;
    this.billingZipTarget.value = main_zip;
  }

  copyMainAddressToOtherAddress(event) {
    event.preventDefault();
    const main_address_first = this.mainAddressFirstTarget.value;
    const main_address_second = this.mainAddressSecondTarget.value;
    const main_city = this.mainCityTarget.value;
    const main_state = this.mainStateTarget.value;
    const main_zip = this.mainZipTarget.value;
    this.otherAddressFirstTarget.value = main_address_first;
    this.otherAddressSecondTarget.value = main_address_second;
    this.otherCityTarget.value = main_city;
    this.otherStateTarget.value = main_state;
    this.otherZipTarget.value = main_zip;
  }

  setSearchUrl(event) {
    const selected_entity = event.target.value.toLowerCase();

    switch (selected_entity) {
      case "accounts":
        document.querySelector(
          "#entity_search_url",
        ).dataset.autocompleteUrlValue =
          "/contacts/search_linked_entity?type=account";
        break;
      case "prospects":
        document.querySelector(
          "#entity_search_url",
        ).dataset.autocompleteUrlValue =
          "/contacts/search_linked_entity?type=prospect";
        break;
      case "leads":
        document.querySelector(
          "#entity_search_url",
        ).dataset.autocompleteUrlValue =
          "/contacts/search_linked_entity?type=lead";
        break;
      case "sites":
        document.querySelector(
          "#entity_search_url",
        ).dataset.autocompleteUrlValue =
          "/contacts/search_linked_entity?type=site";
        break;
      case "vendors":
        document.querySelector(
          "#entity_search_url",
        ).dataset.autocompleteUrlValue =
          "/contacts/search_linked_entity?type=vendor";
        break;
      default:
        document.querySelector(
          "#entity_search_url",
        ).dataset.autocompleteUrlValue =
          "/contacts/search_linked_entity?type=prospect";
    }
  }
}
