import { Controller } from "@hotwired/stimulus";
import { findElement } from "../helpers";

// Connects to data-controller="chat-actions"
export default class extends Controller {
  static targets = ["scroll"];

  connect() {
    // TODO: hide if scroll to bottom https://github.com/stimulus-components/stimulus-scroll-progress/blob/master/src/index.ts
    this._new_messages_alert = findElement(this.element, ".new_messages_alert");
    setTimeout(() => {
      this.resetScroll();
      this.setHeight();
    }, 250);
  }

  scrollTargetConnected() {
    if (
      this.element.scrollTop + 500 <
      this.element.scrollHeight - this.element.clientHeight
    ) {
      this._new_messages_alert.classList.remove("d-none");
    } else {
      this.resetScroll();
    }
  }

  resetScroll() {
    this.element.scrollTop =
      this.element.scrollHeight - this.element.clientHeight;
    this._new_messages_alert.classList.add("d-none");
  }

  setHeight() {
    this.element.style.height = window.innerHeight - 188 + "px";
  }
}
