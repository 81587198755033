import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="single-click"
export default class extends Controller {
  copyAccountMainAddress(event) {
    event.preventDefault();

    // Get values from account address fields
    const account_address_1 =
      document.querySelector("#account_address_1")?.textContent || "";
    const account_address_2 =
      document.querySelector("#account_address_2")?.textContent || "";
    const account_city =
      document.querySelector("#account_city")?.textContent || "";
    const account_state =
      document.querySelector("#account_state_id")?.textContent.trim() || "";
    const account_zip =
      document.querySelector("#account_zip")?.textContent || "";

    // Find the form container
    const form_container = event.target.closest("form");

    // Ensure the form container and selectors are correctly referenced
    if (form_container) {
      const address1Field = form_container.querySelector(
        'input[name="site[address_attributes][address1]"]',
      );
      const address2Field = form_container.querySelector(
        'input[name="site[address_attributes][address2]"]',
      );
      const cityField = form_container.querySelector(
        'input[name="site[address_attributes][city]"]',
      );
      const stateField = form_container.querySelector(
        'select[name="site[address_attributes][state_id]"]',
      );
      const zipField = form_container.querySelector(
        'input[name="site[address_attributes][zip]"]',
      );

      // Check if each field exists before setting its value
      if (address1Field) address1Field.value = account_address_1;
      if (address2Field) address2Field.value = account_address_2;
      if (cityField) cityField.value = account_city;
      if (stateField) stateField.value = account_state;
      if (zipField) zipField.value = account_zip;
    } else {
      console.error("Form container not found");
    }
  }
}
