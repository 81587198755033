import { Controller } from "@hotwired/stimulus";
import Sortable from "sortablejs";

export default class extends Controller {
  connect() {
    this.sortable = Sortable.create(this.element, {
      onEnd: this.end.bind(this),
    });
  }

  async end(event) {
    const id = event.item.dataset.id;
    const newPosition = event.newIndex + 1;
    const data = { position: newPosition };
    const csrfToken = document.querySelector('meta[name="csrf-token"]').content;

    const response = await fetch(this.data.get("url").replace(":id", id), {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
      body: JSON.stringify(data),
    });
  }
}
