import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="dropdown"
export default class extends Controller {
  static targets = [
    "roleid",
    "mainPermission",
    "otherPermission",
    "otherPermissionCheckbox",
    "otherSubpermissionCheckbox",
  ];

  connect() {
    this.fetchPermissions();
  }

  async fetchPermissions() {
    this.mainPermissionTarget.innerHTML = ``;
    this.otherPermissionTarget.innerHTML = ``;
    const selectValue = this.roleidTarget.value;

    if (selectValue === "") {
      this.mainPermissionTarget.innerHtml = "";
      this.otherPermissionTarget.innerHTML = "";
      document.querySelectorAll(".container1").forEach(function (element) {
        element.style.display = "none";
      });
    } else {
      const url = `/fetch_permission/${selectValue}?user_id=${this.roleidTarget.dataset.userId}&user_role=${this.roleidTarget.dataset.userRole}`;
      try {
        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        const data = await response.json();
        if (response.ok) {
          document.querySelectorAll(".container1").forEach(function (element) {
            element.style.display = ""; // Reset to the default display value
          });
          data.permissions.forEach((element) => {
            this.createGroupDynamicContainers(
              element,
              data,
              this.roleidTarget.dataset.actionName,
            );
          });

          data.not_selected.forEach((element) => {
            this.createotherDynamicContainers(element, data);
          });
        }
      } catch (e) {
        console.error(e);
      }
    }
  }

  createGroupDynamicContainers = (element, data, action_name) => {
    const permissions_hash = data.permissions_hash[element.id];
    const mid_container = document.querySelector(".mid-container-main");

    const container2 = document.createElement("div");
    container2.classList.add("container2");

    const content1 = document.createElement("div");
    content1.classList.add("content1");

    // Create the first checkbox for the parent permission
    const checkbox1 = document.createElement("input");
    checkbox1.type = "checkbox";
    checkbox1.id = `permission_${element.id}`;
    checkbox1.className = "percheckbox";
    checkbox1.name = `user[group_permission_ids][]`;
    checkbox1.value = element.id;
    checkbox1.dataset.action = "click->dropdown#toggleSubPermission";
    const usersPermission = data.user_permissions.find(
      (perm) => perm.id === element.id,
    );

    if (usersPermission || action_name === "new") {
      checkbox1.checked = true;
    }

    const label1 = document.createElement("label");
    label1.htmlFor = `permission_${element.id}`;
    label1.textContent = element.name;

    content1.appendChild(checkbox1);
    content1.appendChild(label1);

    const content2 = document.createElement("div");
    content2.classList.add("content2");
    content2.setAttribute("data-dropdown_target", "mainSubpermissionCheckbox");

    const sub_permission_class = `sub_${element.id}`;
    // Iterate over the not selected sub-permissions and create checkboxes for them
    permissions_hash.forEach((sub_permission) => {
      const commonSubPermission = data.common_permissions.find(
        (perm) => perm.id === sub_permission.id,
      );

      const checkbox2 = document.createElement("input");
      checkbox2.type = "checkbox";
      checkbox2.className = `percheckbox ${sub_permission_class}`;
      checkbox2.id = `sub_permission_${sub_permission.id}`;
      checkbox2.name = `user[group_sub_permission_ids][]`; // Name for sub-permissions only
      checkbox2.value = sub_permission.id;

      if (checkbox1.checked !== true) {
        checkbox2.disabled = true;
      }

      const groupPermission = data.group_permissions.find(
        (perm) => perm.id === sub_permission.id,
      );

      if (commonSubPermission || (action_name === "new" && groupPermission)) {
        checkbox2.checked = true;
      }

      const label2 = document.createElement("label");
      label2.htmlFor = `sub_permission_${sub_permission.id}`;
      label2.textContent = sub_permission.name;

      const br_tag = document.createElement("br");

      content2.appendChild(checkbox2);
      content2.appendChild(label2);
      content2.appendChild(br_tag);
    });

    container2.appendChild(content1);
    container2.appendChild(content2);

    mid_container.appendChild(container2);
  };

  // Create rows and columns for permission and sub-permission
  createotherDynamicContainers = (element, data) => {
    const not_selected_sub_permissions = data.not_selected_hash[element.id];
    const mid_container = document.querySelector(".mid-container-sub");

    const container2 = document.createElement("div");
    container2.classList.add("container2");

    const content1 = document.createElement("div");
    content1.classList.add("content1");

    // Create the first checkbox for the parent permission
    const checkbox1 = document.createElement("input");
    checkbox1.type = "checkbox";
    checkbox1.id = `sub_permission_${element.id}`;
    checkbox1.className = "percheckbox";
    checkbox1.name = `user[other_permission_ids][]`;
    checkbox1.value = element.id;
    checkbox1.dataset.action = "click->dropdown#toggleSubPermission";

    const commonPermission = data.common_permissions.find(
      (perm) => perm.id === element.id,
    );

    if (commonPermission) {
      checkbox1.checked = true;
    }

    const label1 = document.createElement("label");
    label1.htmlFor = `permission_${element.id}`;
    label1.textContent = element.name;
    content1.appendChild(checkbox1);
    content1.appendChild(label1);
    const content2 = document.createElement("div");
    content2.classList.add("content2");
    content2.setAttribute("data-dropdown_target", "otherSubpermissionCheckbox");
    const sub_permission_class = `sub_${element.id}`;
    // Iterate over the not selected sub-permissions and create checkboxes for them
    not_selected_sub_permissions.forEach((sub_permission) => {
      const commonSubPermission = data.common_permissions.find(
        (perm) => perm.id === sub_permission.id,
      );

      const checkbox2 = document.createElement("input");
      checkbox2.type = "checkbox";
      checkbox2.className = `percheckbox ${sub_permission_class}`;
      checkbox2.id = `sub_permission_${sub_permission.id}`;
      checkbox2.name = `user[other_sub_permission_ids][]`; // Name for sub-permissions only
      checkbox2.value = sub_permission.id;

      if (checkbox1.checked !== true) {
        checkbox2.disabled = true;
      }

      if (commonSubPermission) {
        checkbox2.checked = true;
      }

      const label2 = document.createElement("label");
      label2.htmlFor = `sub_permission_${sub_permission.id}`;
      label2.textContent = sub_permission.name;

      const br_tag = document.createElement("br");

      content2.appendChild(checkbox2);
      content2.appendChild(label2);
      content2.appendChild(br_tag);
    });

    container2.appendChild(content1);
    container2.appendChild(content2);
    mid_container.appendChild(container2);
  };

  // Toggle the sub-permission checkboxes
  toggleSubPermission = (event) => {
    const clickedCheckbox = event.target;
    const value = clickedCheckbox.value;
    const subPermissionCheckboxes = document.querySelectorAll(`.sub_${value}`);

    subPermissionCheckboxes.forEach((checkbox) => {
      checkbox.checked = false; // Toggle the checked state
      checkbox.disabled = !checkbox.disabled; // Toggle the disabled state
    });
  };

  // conert array values into lower case
  joinArrValue(arr) {
    return arr.map((ele) => ele.toLowerCase());
  }
}
