import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "favoriteForm",
    "favoriteValue",
    "favoriteIcon",
    "favoriteField",
    "stockField",
  ];

  updateFavorite(event) {
    const new_favorite_value = event.currentTarget.dataset.favoriteValue;
    const favorite_form = this.favoriteFormTarget;
    const favorite_value = this.favoriteValueTarget;

    favorite_value.value = new_favorite_value;
    this.updateIcons(this.favoriteIconTargets);
    favorite_form.submit();
  }

  setProductField(event) {
    const favoriteField = this.favoriteFieldTarget;
    const favorite_value = event.currentTarget.dataset.favoriteValue;

    favoriteField.value = favorite_value;

    this.updateIcons(this.favoriteIconTargets);
  }

  updateIcons(favorite_icons) {
    favorite_icons.forEach((icon) => {
      icon.classList.toggle("d-none");
    });
  }

  updateUnit(event) {
    const selectedIndex = event.target.selectedIndex;
    const selectedOption = event.target.options[selectedIndex];
    const selectedText = selectedOption.text;

    this.stockFieldTargets.forEach((field) => {
      field.innerText = selectedText;
    });
  }

  async AddSimilarProduct(event) {
    const similar_product_id = this.element.querySelector(
      "#similar_product_hidden_field_id",
    ).value;
    const current_product_id = this.element.querySelector(
      "#current_product_id",
    ).value;

    try {
      const response = await fetch(
        `/products/${current_product_id}/add_similar_product?similar_product_id=${similar_product_id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        },
      );
      const data = await response.json();
      if (response.ok) {
      } else {
        if (data.errors) {
          this.showErrors(data.errors, this.errorsListTarget);
        } else {
          console.error("Request failed:", response);
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }
}
