import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "password",
    "username",
    "url",
    "copyUrlIcon",
    "doneUrlIcon",
    "doneIcon",
    "copyIcon",
    "copyUsernameIcon",
    "doneUsernameIcon",
  ];

  copyPassword() {
    const passwordField = this.passwordTarget.value;

    navigator.clipboard
      .writeText(passwordField)
      .then(() => {
        console.log("Text copied");
        this.toggleButton();
      })
      .catch((err) => {
        console.error("Error copying text:", err);
      });
  }

  toggleButton() {
    const done_icon = this.doneIconTarget;
    const copy_icon = this.copyIconTarget;

    done_icon.classList.toggle("d-none");
    copy_icon.classList.toggle("d-none");
  }

  copyUsername() {
    const usernameField = this.usernameTarget.innerText;

    navigator.clipboard
      .writeText(usernameField)
      .then(() => {
        console.log("Text copied");
        this.toggleUsernameButton();
      })
      .catch((err) => {
        console.error("Error copying text:", err);
      });
  }

  toggleUsernameButton() {
    const done_username_icon = this.doneUsernameIconTarget;
    const copy_username_icon = this.copyUsernameIconTarget;

    done_username_icon.classList.toggle("d-none");
    copy_username_icon.classList.toggle("d-none");
  }

  copyUrl() {
    const urlField = this.urlTarget.innerText;

    navigator.clipboard
      .writeText(urlField)
      .then(() => {
        console.log("Text copied");
        this.toggleUrlButton();
      })
      .catch((err) => {
        console.error("Error copying text:", err);
      });
  }

  toggleUrlButton() {
    const done_url_icon = this.doneUrlIconTarget;
    const copy_url_icon = this.copyUrlIconTarget;

    done_url_icon.classList.toggle("d-none");
    copy_url_icon.classList.toggle("d-none");
  }
}
