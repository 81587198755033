import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["qrCode"];

  print(event) {
    event.preventDefault();
    const printWindow = window.open("", "_blank");
    const product_details = event.target.closest(
      '[data-action="qr-code#print"]',
    ).dataset.htmlText;
    const header_text = event.target.closest('[data-action="qr-code#print"]')
      .dataset.headerText;

    printWindow.document.write(
      `<html><head><title>${header_text}</title><style>`,
      "body { font-family: Arial, sans-serif; background-color: #f4f4f9; margin: 20px; }",
      ".container { padding: 20px; }",
      ".header { font-size: 24px; font-weight: bold; margin-bottom: 10px; }",
      ".details { font-size: 18px; margin-bottom: 5px; }",
      "</style></head><body>",
      "<div class='container'>",
      product_details,
      "</div>",
      "</body></html>",
    );
    printWindow.document.write(this.qrCodeTarget.outerHTML);
    printWindow.document.write("</body></html>");
    printWindow.document.close();
    printWindow.print();
  }
}
