import { Controller } from "@hotwired/stimulus";
import Tribute from "tributejs";

export default class extends Controller {
  static targets = ["mentionUser", "channelName"];

  async connect() {
    try {
      const users = await this.fetch_users();
      const values = Object.entries(users).map(([fullName, username]) => ({
        key: fullName,
        value: username,
      }));
      const tribute = new Tribute({ values: values });

      const textArea = this.mentionUserTarget;
      if (textArea) {
        tribute.attach(textArea);
      }
    } catch (error) {
      console.error("Error initializing Tribute:", error);
    }
  }

  async fetch_users() {
    const baseUrl = "/users/mention_data_summary";
    let url;
    if (this.hasChannelNameTarget) {
      const channelName = this.channelNameTarget.textContent
        .trim()
        .replace(/^#/, "");
      url = `${baseUrl}?channel_name=${encodeURIComponent(channelName)}`;
    } else {
      url = baseUrl;
    }

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        const data = await response.json();
        return data;
      } else {
        throw new Error("Network response was not ok.");
      }
    } catch (error) {
      console.error("Failed to fetch users:", error);
      throw error;
    }
  }
}
