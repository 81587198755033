import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="set-to-hidden"
export default class extends Controller {
  static targets = ["hidden"];

  set_value(event) {
    event.preventDefault();
    this.hiddenTarget.value = event.target.dataset.statusValue;

    // Disable all buttons in the form
    const buttons = this.element.querySelectorAll('button[type="button"]');
    buttons.forEach((button) => {
      button.disabled = true;
      button.classList.add("disabled");
    });

    this.element.submit();
  }
}
