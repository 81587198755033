import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "cloudUrlInput",
    "documentInput",
    "cloudUrlRadio",
    "documentRadio",
    "container",
    "nameField",
  ];

  toggleInputs(event) {
    const cloudUrlInput = this.cloudUrlInputTarget;
    const documentInput = this.documentInputTarget;
    const cloudUrlRadio = this.cloudUrlRadioTarget;
    const documentRadio = this.documentRadioTarget;
    const container = this.containerTarget;

    if (event.target === cloudUrlInput) {
      documentInput.value = "";
      cloudUrlRadio.checked = true;
      container.classList.add("option-1");
      container.classList.remove("option-2");
    } else if (event.target === documentInput) {
      cloudUrlInput.value = "";
      documentRadio.checked = true;
      container.classList.add("option-2");
      container.classList.remove("option-1");
    }
  }

  validateForm(event) {
    this.clearErrors();

    const nameField = document.getElementById("nameField");
    const cloudUrlInput = document.getElementById("cloud_url_input");
    const documentInput = document.getElementById("document_input");
    const linkeddocument = document.getElementById("linkedDocument");

    let isValid = true;

    if (!nameField.value.trim()) {
      this.showError("nameError", "Name is required.");
      isValid = false;
    } else {
      this.hideError("nameError");
    }

    const cloudUrlError = document.getElementById("cloudUrlError");
    const documentError = document.getElementById("documentError");

    if (
      !cloudUrlInput.value.trim() &&
      !documentInput.files.length &&
      linkedDocument.querySelector("a") == null
    ) {
      this.showError(
        "documentError",
        "Either a document must be attached or a cloud URL must be provided.",
      );
      isValid = false;
    } else {
      this.hideError("documentError");
    }

    if (
      cloudUrlInput.value.trim() &&
      !/^https?:\/\//.test(cloudUrlInput.value)
    ) {
      this.showError(
        "cloudUrlError",
        "URL must start with http:// or https://",
      );
      isValid = false;
    } else {
      this.hideError("cloudUrlError");
    }

    if (!isValid) {
      event.preventDefault();
      4;
    }
  }

  showError(errorId, message) {
    const errorElement = document.getElementById(errorId);
    if (errorElement) {
      errorElement.textContent = message;
      errorElement.classList.remove("d-none");
    }
  }

  hideError(errorId) {
    const errorElement = document.getElementById(errorId);
    if (errorElement) {
      errorElement.textContent = "";
      errorElement.classList.add("d-none");
    }
  }

  clearErrors() {
    // Clear previous error messages
    const errorElements = document.querySelectorAll(".text-danger");
    errorElements.forEach((el) => {
      el.textContent = "";
      el.classList.add("d-none");
    });
  }

  checkNameValidations() {
    const nameField = document.getElementById("nameField");

    if (!nameField.value.trim()) {
      this.showError("nameError", "Name is required.");
    } else {
      this.hideError("nameError");
    }
  }

  checkUrlAndDocumentValidations() {
    const cloudUrlInput = document.getElementById("cloud_url_input");
    const documentInput = document.getElementById("document_input");
    const linkeddocument = document.getElementById("linkedDocument");

    if (
      cloudUrlInput.value.trim() &&
      !/^https?:\/\//.test(cloudUrlInput.value)
    ) {
      this.showError(
        "cloudUrlError",
        "URL must start with http:// or https://",
      );
    } else {
      this.hideError("cloudUrlError");
    }

    if (
      !cloudUrlInput.value.trim() &&
      !documentInput.files.length &&
      linkedDocument.querySelector("a") == null
    ) {
      this.showError(
        "documentError",
        "Either a document must be attached or a cloud URL must be provided.",
      );
    } else {
      this.hideError("documentError");
    }
  }
}
