import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    // Add an event listener for input on the stop date field
    const stopDateField = this.element.querySelector('input[name="date_end"]');
    stopDateField.addEventListener("input", () => this.toggleSubmitButton());
  }

  validate_form(event) {
    const recurringDaysList = this.element.querySelector(
      ".recurring_days_list",
    );
    const stopDateField = this.element.querySelector('input[name="date_end"]');
    const submitButton = this.element.querySelector('button[type="submit"]');

    // Reset button state
    submitButton.disabled = false;

    // Check if the recurring days list is visible
    if (!recurringDaysList.classList.contains("d-none")) {
      // Validate stop date
      if (!stopDateField.value) {
        submitButton.disabled = true; // Disable submit button
        event.preventDefault(); // Prevent form submission
      }
    }
  }

  toggleSubmitButton() {
    const stopDateField = this.element.querySelector('input[name="date_end"]');
    const submitButton = this.element.querySelector('button[type="submit"]');

    // Enable the button if the stop date has a value
    submitButton.disabled = !stopDateField.value;
  }
}
