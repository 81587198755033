import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  toggleErrors(event) {
    const errorId = event.target.dataset.errorId;
    const errorElement = this.element.querySelector(`#${errorId}`);

    if (event.target.value) {
      errorElement.classList.add("d-none"); // Hide error message
    } else {
      errorElement.classList.remove("d-none"); // Show error message
    }
  }
}
