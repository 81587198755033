import { Controller } from "@hotwired/stimulus";

const phoneNumberPattern = /^\+\d+/;

export default class extends Controller {
  static targets = [
    "submitButton",
    "phoneNumber",
    "communicationPreference",
    "phoneNumberSublabel",
  ];

  connect() {
    this.validate();
  }

  validate() {
    if (this.communicationPreferenceTarget.value != "Email") {
      if (!phoneNumberPattern.test(this.phoneNumberTarget.value)) {
        this.restrictSubmission(true);
      } else {
        this.restrictSubmission(false);
      }
    } else {
      this.restrictSubmission(false);
    }
  }

  restrictSubmission(flag) {
    this.submitButtonTarget.disabled = flag;
    this.phoneNumberSublabelTarget.classList.toggle("d-none", !flag);
  }
}
