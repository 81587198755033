import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["element", "calendar"];

  connect() {
    if (this.hasCalendarTarget) {
      window.addEventListener("resize", this.handleResize.bind(this));
      this.handleResize();
    } else {
      this.shouldHandleScroll = true;
    }
    window.addEventListener("scroll", this.handleScroll.bind(this));
  }

  disconnect() {
    if (this.hasCalendarTarget) {
      window.removeEventListener("resize", this.handleResize.bind(this));
    }
    window.removeEventListener("scroll", this.handleScroll.bind(this));
  }

  handleScroll() {
    if (this.shouldHandleScroll) {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const threshold = 168;
      this.elementTargets.forEach((element) => {
        if (scrollTop > threshold) {
          element.classList.add("fixed");
        } else {
          element.classList.remove("fixed");
        }
      });
    }
  }

  handleResize() {
    if (this.hasCalendarTarget) {
      const hasHorizontalScrollbar =
        this.calendarTarget.scrollWidth > this.calendarTarget.clientWidth;
      if (hasHorizontalScrollbar) {
        this.elementTargets.forEach((element) => {
          element.classList.remove("fixed");
        });
        this.shouldHandleScroll = false;
      } else {
        if (!this.shouldHandleScroll) {
          this.shouldHandleScroll = true;
          this.handleScroll();
        }
      }
    }
  }
}
