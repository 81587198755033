import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [];
  async filter_wo_by_disable_status(event) {
    const newStatus = event.target.dataset.value;
    let currentUrl = new URL(window.location.href);
    if (currentUrl.searchParams.has("disable_status")) {
      currentUrl.searchParams.delete("disable_status");
    }

    if (newStatus === "all") {
      window.location.href = currentUrl.toString();
    } else {
      currentUrl.searchParams.append("disable_status", newStatus);
      window.location.href = currentUrl.toString();
    }
  }
}
