import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["list", "template", "button"];
  static values = {
    confirm: { type: String, default: "Are you sure you want to delete?" },
  };

  add_association(event) {
    event.preventDefault();

    var content = this.templateTarget.innerHTML.replace(
      /NEW_RECORD/g,
      new Date().getTime(),
    );
    this.listTarget.insertAdjacentHTML("beforeend", content);
  }

  remove_association(event) {
    event.preventDefault();

    let wrapper = event.target.closest(".nested-fields");
    if (wrapper.dataset.newRecord == "true") {
      wrapper.remove();
    } else {
      if (confirm(this.confirmValue)) {
        wrapper.querySelector("input[name*='_destroy']").value = 1;
        wrapper.style.display = "none";
      }
    }
  }

  toggle_add_button(event) {
    event.preventDefault();
    const phone_count =
      this.listTarget.querySelectorAll(".nested-fields").length;
    if (phone_count == 1) {
      this.buttonTarget.classList.toggle("d-none");
    }
  }
}
