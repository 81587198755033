import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "message"];

  validateUrl(event) {
    const input = this.inputTarget;
    const value = input.value.trim();

    if (!this.isValidUrl(value)) {
      this.showError(
        "Please enter a valid URL starting with http:// or https://",
      );
    }
  }

  isValidUrl(url) {
    const pattern = /^https?:\/\/.+/;
    return pattern.test(url);
  }

  showError(message) {
    this.messageTarget.textContent = message;
    this.messageTarget.classList.add("text-danger"); // Adjust styling as needed
  }
}
