import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "serviceList",
    "fieldServiceButton",
    "newServiceList",
    "newServiceInput",
    "errorsListService",
    "sourceList",
    "fieldSourceButton",
    "newSourceList",
    "newSourceInput",
    "errorsListSource",
    "WoTypeList",
    "newWoTypeList",
    "newWoTypeInput",
    "fieldWoTypeButton",
    "errorsListWoType",
  ];

  showNewServiceField() {
    this.fieldServiceButtonTarget.classList.toggle("d-none");
    this.newServiceListTarget.classList.toggle("d-none");
  }

  showNewSourceField() {
    this.fieldSourceButtonTarget.classList.toggle("d-none");
    this.newSourceListTarget.classList.toggle("d-none");
  }

  showWoTypeField() {
    this.fieldWoTypeButtonTarget.classList.toggle("d-none");
    this.newWoTypeListTarget.classList.toggle("d-none");
  }

  async addNewServiceElement() {
    const new_data_element = this.newServiceInputTarget.value;
    try {
      const response = await fetch(
        `settings/create_service_source?service_name=${new_data_element}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        },
      );
      const data = await response.json();
      if (response.ok) {
        const list = this.serviceListTarget;
        // Create a new li element for the newly created service
        this.addListItem(list, data.new_data.id, data.new_data.name, "service");
        this.showNewServiceField();
        this.newServiceInputTarget.value = "";
        this.errorsListServiceTarget.classList.add("d-none");
      } else {
        if (data.errors) {
          this.showErrors(data.errors, this.errorsListServiceTarget);
        } else {
          console.error("Request failed:", response);
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }

  async addNewSourceElement() {
    const new_data_element = this.newSourceInputTarget.value;
    try {
      const response = await fetch(
        `settings/create_service_source?source_name=${new_data_element}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        },
      );
      const data = await response.json();
      if (response.ok) {
        const list = this.sourceListTarget;
        this.addListItem(
          list,
          data.new_data.id,
          data.new_data.name,
          "account_source",
        );
        // Create a new li element for the newly created account source
        this.showNewSourceField();
        this.newSourceInputTarget.value = "";
        this.errorsListSourceTarget.classList.add("d-none");
      } else {
        if (data.errors) {
          this.showErrors(data.errors, this.errorsListSourceTarget);
        } else {
          console.error("Request failed:", response);
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }

  async addNewWoTypeElement() {
    const new_data_element = this.newWoTypeInputTarget.value;
    try {
      const response = await fetch(
        `settings/create_wo_type?wo_type_name=${new_data_element}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        },
      );
      const data = await response.json();
      if (response.ok) {
        const list = this.WoTypeListTarget;
        // Create a new li element for the newly created wo type
        this.addListItem(list, data.new_data.id, data.new_data.name, "wo_type");
        this.showWoTypeField();
        this.newWoTypeInputTarget.value = "";
        this.errorsListWoTypeTarget.classList.add("d-none");
      } else {
        if (data.errors) {
          this.showErrors(data.errors, this.errorsListWoTypeTarget);
        } else {
          console.error("Request failed:", response);
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }

  // this function will add new li for service/account_source
  addListItem(list, value, text_content, context) {
    const newLi = document.createElement("li");
    newLi.textContent = text_content;

    if (context === "service") {
      newLi.innerHTML += `<a href="/settings/remove_service_source?service_id=${value}"><i class="bi bi-x-square me-1 mt-0 fs-6 btn btn"></i></a>`;
    } else if (context === "account_source") {
      newLi.innerHTML += `<a href="/settings/remove_service_source?source_id=${value}"><i class="bi bi-x-square me-1 mt-0 fs-6 btn btn"></i></a>`;
    } else if (context === "wo_type") {
      newLi.innerHTML += `<a href="/settings/remove_wo_type?wo_type_id=${value}"><i class="bi bi-x-square me-1 mt-0 fs-6 btn btn"></i></a>`;
    }

    list.appendChild(newLi);
  }

  showErrors(errors, error_container) {
    error_container.textContent = "";

    const errorElements = Object.entries(errors)
      .map(([attribute, attributeErrors]) =>
        attributeErrors.map((errorMessage) => `${attribute} ${errorMessage}`),
      )
      .flat()
      .map((errorMessage) => {
        const errorMessageElement = document.createElement("div");
        errorMessageElement.textContent = errorMessage;
        return errorMessageElement;
      });

    error_container.append(...errorElements);
    error_container.classList.remove("d-none");
  }

  async send_selected_type(event) {
    const value = event.target.value;
    const url = `settings/change_filter_type?filter_type=${value}`;
    try {
      const response = await fetch(url, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": document.querySelector("meta[name='csrf-token']")
            .content,
        },
      });
      const text = await response.text();
      document.body.insertAdjacentHTML("beforeend", text);

      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
    } catch (error) {
      console.log("Error", error);
    }
  }

  updateCompanySetting(event) {
    const target_form = event.target.closest("form");
    target_form.requestSubmit();
  }

  async updateCopySender(event) {
    const isChecked = event.target.checked;
    const emailType = event.target.dataset.emailType;

    try {
      const response = await fetch("/settings/update_copy_sender", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": document.querySelector("meta[name='csrf-token']")
            .content,
        },
        body: JSON.stringify({
          notification_email: { send_copy: isChecked, email_type: emailType },
        }),
      });

      const data = await response.json();
      event.target.checked = data.send_copy;
    } catch (error) {
      console.error("Error:", error);
    }
  }
}
