import { Controller } from "@hotwired/stimulus";

// https://stackoverflow.com/questions/65985735/how-to-open-action-text-trix-attachments-or-links-in-new-window-target-blank
export default class extends Controller {
  connect() {
    this.element.querySelectorAll("a").forEach((link) => {
      if (link.host !== window.location.host) {
        link.target = "_blank";
      }
    });
  }
}
