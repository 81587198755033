import { Controller } from "@hotwired/stimulus";
import * as bootstrap from "bootstrap";

export default class extends Controller {
  connect() {
    const content = this.element.getAttribute("data-content");
    const placement = this.element.getAttribute("data-placement") || "right";
    new bootstrap.Popover(this.element, {
      content,
      placement,
      trigger: "hover",
    });
  }
}
